import { ImageWrapperWithFallback } from "src/components/atoms/_common/ImageWrapperWithFallback/ImageWrapperWithFallback";
import * as S from "./strength.style";
import { useEffect, useRef, useState } from "react";
import { useWindowScrollY } from "src/businesslogics/_layout/window";
import { ArrowChevronRight } from "@teamsparta/design-system";
import { sendCPLog } from "@teamsparta/cross-platform-logger";

export const Strength = () => {
  const [isFirstStrength, setIsFirstStrength] = useState(true);
  const targetRef = useRef(null);
  const scrollY = useWindowScrollY();

  useEffect(() => {
    const target = targetRef.current;

    setIsFirstStrength(target.offsetTop + target.offsetHeight / 4 > scrollY);
  }, [scrollY]);

  const onClickActiveBox = (isFirstStrength, feature_title) => {
    sendCPLog("scc_mainPage_feature_click", {
      feature_title,
    });
    const topOffset =
      targetRef.current.offsetTop +
      (isFirstStrength ? 0 : targetRef.current.offsetHeight / 3);

    window.scrollTo({
      top: topOffset,
      behavior: "smooth",
    });
  };

  return (
    <>
      <S.MobileContainer>
        <S.TitleContainer>
          <S.SubTtile>수강환경</S.SubTtile>
          <S.Title>코딩 입문을 위한 완벽한 수강환경</S.Title>
        </S.TitleContainer>
        <S.Desc>
          스파르타코딩클럽에서는
          <br />
          완주까지 막힘없이 수강할 수 있도록
          <br />
          <b>왕초보에게 최적의 수강환경</b>을 제공해요
        </S.Desc>
        <S.Divide />
        <S.CardContainer>
          <S.CardTitleContainer>
            <S.SubTtile>신청만 하고 잊는 일은 없도록</S.SubTtile>
            <S.CardTitle>찐한관리</S.CardTitle>
          </S.CardTitleContainer>
          <ImageWrapperWithFallback>
            <S.CardImage
              src={`/assets/images/home/v4/strength_1.webp`}
              isShow={true}
            />
          </ImageWrapperWithFallback>
        </S.CardContainer>
        <S.CardContainer>
          <S.CardTitleContainer>
            <S.SubTtile>확실하게 알고 넘어가도록</S.SubTtile>
            <S.CardTitle>즉문즉답</S.CardTitle>
          </S.CardTitleContainer>
          <ImageWrapperWithFallback>
            <S.CardImage
              src={`/assets/images/home/v4/strength_2.webp`}
              isShow={true}
            />
          </ImageWrapperWithFallback>
        </S.CardContainer>
      </S.MobileContainer>

      <S.DesktopWrap ref={targetRef}>
        <S.Container>
          <S.TitleContainer>
            <S.SubTtile>수강환경</S.SubTtile>
            <S.Title>코딩 입문을 위한 완벽한 수강환경</S.Title>
          </S.TitleContainer>
          <S.ContentContainer>
            <S.ContentTextContainer>
              <S.Desc>
                스파르타코딩클럽에서는
                <br />
                완주까지 막힘없이 수강할 수 있도록
                <br />
                <b>왕초보에게 최적의 수강환경</b>을 제공해요
              </S.Desc>
              <S.Divide />

              <S.ActiveBoxContainer>
                <S.ActiveBox
                  isActive={isFirstStrength}
                  onClick={() => {
                    onClickActiveBox(true, "찐한관리");
                  }}
                >
                  <S.BoxTitleContainer>
                    <S.BoxSubTitle isActive={isFirstStrength}>
                      신청만 하고 잊는 일은 없도록
                    </S.BoxSubTitle>
                    <S.BoxTitle isActive={isFirstStrength}>찐한관리</S.BoxTitle>
                  </S.BoxTitleContainer>
                  <ArrowChevronRight
                    size={24}
                    color={isFirstStrength ? "#141617" : "#B4BFC6"}
                  />
                </S.ActiveBox>
                <S.ActiveBox
                  isActive={!isFirstStrength}
                  onClick={() => {
                    onClickActiveBox(false, "즉문즉답");
                  }}
                >
                  <S.BoxTitleContainer>
                    <S.BoxSubTitle isActive={!isFirstStrength}>
                      확실하게 알고 넘어가도록
                    </S.BoxSubTitle>
                    <S.BoxTitle isActive={!isFirstStrength}>
                      즉문즉답
                    </S.BoxTitle>
                  </S.BoxTitleContainer>
                  <ArrowChevronRight
                    size={24}
                    color={!isFirstStrength ? "#141617" : "#B4BFC6"}
                  />
                </S.ActiveBox>
              </S.ActiveBoxContainer>
            </S.ContentTextContainer>

            <ImageWrapperWithFallback>
              <S.CardImage
                isShow={isFirstStrength}
                src={`/assets/images/home/v4/strength_1.webp`}
              />
            </ImageWrapperWithFallback>

            <ImageWrapperWithFallback>
              <S.CardImage
                isShow={!isFirstStrength}
                src={`/assets/images/home/v4/strength_2.webp`}
              />
            </ImageWrapperWithFallback>
          </S.ContentContainer>
        </S.Container>
      </S.DesktopWrap>
    </>
  );
};
