import { MONTHLY_SPECIAL } from "src/models/online/special";

export const moveToSpecificCatalog = (location) => {
  window.location.href = `${process.env.BASE_URL}/catalog?scroll=${location}`;
};

export const moveToMonthlySpecial = () => {
  window.location.href = `${process.env.BASE_URL}/online/special/${MONTHLY_SPECIAL}`;
};

export const moveToMonthly = (url) => {
  window.location.href = `${process.env.BASE_URL}/online/special/${url}`;
};

export const moveToHref = (href) => {
  window.location.href = href;
};

export const moveToHrefOpenWindow = (herf) => {
  window.open(herf);
};
