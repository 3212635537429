import { NextImageWithFallback } from "src/components/atoms/_common/NextImageWithFallback/NextImageWithFallback";
import * as S from "./bootCamp.style";
import { useRouter } from "next/router";
import { Outro } from "../Outro/Outro";
import { sendLogV2 } from "src/businesslogics/_common/loggingV2";
import { useCustomRemoteConfig } from "src/businesslogics/_common/hackle/useCustomRemoteConfig";
import { convertNbcTrackCardImg } from "src/businesslogics/home/v4/convertNbcTrackCardImg";

interface TrackData {
  track_keys: string[];
  tracks: Track[];
}

interface Track {
  track_key: string;
  track_title: string;
  track_tag: string;
  track_description: string;
  track_link: string;
}

export const BootCamp = () => {
  const router = useRouter();
  const trackData: TrackData = useCustomRemoteConfig(
    "scc_main_nbcTrackCard",
    "json"
  );

  if (!trackData || !("track_keys" in trackData) || !("tracks" in trackData)) {
    return null;
  }

  const convertedTracks = convertNbcTrackCardImg(
    trackData.track_keys,
    trackData.tracks
  );


  const onClickCard = (url: string, courseTitle) => {
    sendLogV2("bootcamp_courseCuration_card_click", router.pathname, {
      course_title: courseTitle,
    });
    router.push(url);
  };

  return (
    convertedTracks && (
      <S.BootCampContainer>
        <S.Container>
          <S.TitleContainer>
            <S.SubTitle>IT 직군 취업은 내일배움캠프</S.SubTitle>
            <S.Title>
              4개월 몰입형 부트캠프도
              <br />
              전액 국비지원으로
            </S.Title>
          </S.TitleContainer>
          <S.ContentContainer>
            <S.ResultBox>
              <S.ResultItem>
                <S.ResultTitle>비전공자 비율</S.ResultTitle>
                <S.ResultValue>
                  92.3<S.ResultPersent>%</S.ResultPersent>
                </S.ResultValue>
              </S.ResultItem>
              <S.ResultItem>
                <S.ResultTitle>수료율</S.ResultTitle>
                <S.ResultValue>
                  91.2<S.ResultPersent>%</S.ResultPersent>
                </S.ResultValue>
              </S.ResultItem>
              <S.ResultItem>
                <S.ResultTitle>6개월 내 취업율</S.ResultTitle>
                <S.ResultValue>
                  62.3<S.ResultPersent>%</S.ResultPersent>
                </S.ResultValue>
              </S.ResultItem>
            </S.ResultBox>
            <S.BootCampCourseContainer>
              {convertedTracks.map((trackCard, index) => (
                <S.CourseBox
                  key={`bootcamp-${trackCard.track_title}-${index}`}
                  onClick={() => {
                    onClickCard(trackCard.track_link, trackCard.track_title);
                  }}
                >
                  <S.CourseImageWrap>
                    <NextImageWithFallback
                      src={trackCard.track_key}
                      layout="fill"
                      alt={trackCard.track_title}
                    />
                  </S.CourseImageWrap>
                  <S.CourseTextBox>
                    <S.CourseTextInnerBox>
                      <S.CourseTitleContainer>
                        <S.CourseTitle>{trackCard.track_title}</S.CourseTitle>
                        <S.FirstComeBox>
                          <S.FirstComeText>
                            {trackCard.track_tag || "선착순 100명"}
                          </S.FirstComeText>
                        </S.FirstComeBox>
                      </S.CourseTitleContainer>
                      <S.CourseDesc>{trackCard.track_description}</S.CourseDesc>
                    </S.CourseTextInnerBox>
                    <S.CourseTitleRed>무료</S.CourseTitleRed>
                  </S.CourseTextBox>
                </S.CourseBox>
              ))}
            </S.BootCampCourseContainer>
          </S.ContentContainer>
        </S.Container>
        <Outro />
      </S.BootCampContainer>
    )
  );
};
