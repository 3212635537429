import { useState, useEffect } from "react";

export const useCountUp = (start, end, duration) => {
  const [count, setCount] = useState(start);

  useEffect(() => {
    if (start === end || duration === 0) {
      setCount(end);
      return;
    }

    const increment = (end - start) / (duration / 10);
    const interval = setInterval(() => {
      setCount((prevCount) => {
        const nextCount = prevCount + increment;
        if (increment > 0 ? nextCount >= end : nextCount <= end) {
          clearInterval(interval);
          return end;
        }
        return nextCount;
      });
    }, 10);

    return () => clearInterval(interval);
  }, [start, end, duration]);

  return Number(Math.min(Math.max(count, start), end).toFixed(0));
};
