import styled from "@emotion/styled";
import {
  mBody1,
  mBody2,
  mBody3,
  neutralDay,
  scc,
  wBody1,
  wBody2,
} from "@teamsparta/design-system";
import { DisplayResolution } from "src/styles/themes/device";

export const Container = styled.button`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;

  width: 158px;
  height: fit-content;

  ${DisplayResolution.TabletAndDesktop} {
    width: 100%;
  }
`;

export const CardThumb = styled.img`
  width: 158px;
  height: 100px;
  border-radius: 8px;

  ${DisplayResolution.TabletAndDesktop} {
    width: 100%;
    height: 100%;
    aspect-ratio: 275 /175;
  }
`;

export const CardTextWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
`;

export const CardTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
`;

export const Title = styled.h3`
  text-align: left;
  ${mBody1};
  color: ${neutralDay.gray100};

  ${DisplayResolution.TabletAndDesktop} {
    ${wBody1};

    color: ${neutralDay.gray100};
  }
`;

export const Desc = styled.p`
  white-space: pre-wrap;
  text-align: left;

  ${mBody2};
  color: ${neutralDay.gray80};

  ${DisplayResolution.TabletAndDesktop} {
    ${wBody2};
    color: ${neutralDay.gray80};
  }
`;

export const Price = styled.p`
  ${mBody1};
  color: ${scc.red100};

  ${DisplayResolution.TabletAndDesktop} {
    ${wBody1};
    color: ${scc.red100};
  }
`;

export const CtaButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;

  min-width: 64px;
  width: 100%;
  height: 36px;
  border-radius: 6px;
  border: 1px solid ${neutralDay.gray30};
  background: ${neutralDay.white};
  gap: 2px;

  ${DisplayResolution.TabletAndDesktop} {
    border-radius: 8px;
    height: 44px;

    &:hover {
      background: ${neutralDay.gray0};
    }
  }
`;

export const CtaText = styled.span`
  ${mBody3};
  color: ${neutralDay.gray90};
  text-align: center;

  ${DisplayResolution.TabletAndDesktop} {
    ${mBody1};
    color: ${neutralDay.gray90};
  }
`;
