const hanghae = {
  key: "hanghae",
  title: (
    <>
      개발자 교육을 넘어
      <br />
      취업까지 책임지는, 항해99
    </>
  ),
  desc: <>2023 새해 맞이 얼리버드 할인 중(~1/16)</>,
  background: {
    color: "#c20000",
    mobile: "/assets/images/home/carousel/carousel_item_mo_hanghae.png",
    desktop: "/assets/images/home/carousel/carousel_item_web_hanghae.png",
  },
  cta: {
    text: "더 알아보기",
    href: "https://hanghae99.spartacodingclub.kr/",
  },
  bannerName: "항해99",
  bannerTitle:
    "2023년에는 개발자로 거듭나세요. 새해 가격 인상 전 마지막 할인 중",
};

const big_thing = {
  key: "big_thing",
  title: (
    <>
      &quot;헉 큰일났다&quot;
      <br />
      큰일난 사람들의 큰일낸 이야기
    </>
  ),
  desc: <>실제 수강생들의 이야기와 목소리를 담았습니다.</>,
  background: {
    color: "#FF92A7",
    mobile: "/assets/images/home/carousel/carousel_item_mo_big_thing.png",
    desktop: "/assets/images/home/carousel/carousel_item_web_big_thing.png",
  },
  cta: {
    text: "더 알아보기",
    href: "/community/2022awards",
  },
  bannerName: "스파르타 큰일어워즈",
  bannerTitle: "큰일 어워즈 캐러셀",
};

const rcmdsFree = {
  key: "rcmdsfree",
  title: (
    <>
      친구랑 버킷리스트 만들고
      <br />
      푸짐한 상품까지 받아가세요!
    </>
  ),
  desc: <>월간코딩 한정, 친구추천 이벤트 진행 중</>,
  background: {
    color: "#E8344E",
    mobile: "/assets/images/home/carousel/carousel_item_mo_rcmdsfree.png",
    desktop: "/assets/images/home/carousel/carousel_item_web_rcmdsfree.png",
  },
  cta: {
    text: "더 알아보기",
    href: `${process.env.BASE_URL}/rcmdsfree`,
  },
  bannerName: "월간코딩",
  bannerTitle: "2023 나만의 버킷리스트 만들기 ",
};

const sql = {
  title: (
    <>
      지금 SQL 수강신청 시<br />
      자격증 대비반 무료 참여 기회!
    </>
  ),
  desc: <>실무 SQL부터 자격증까지 한 번에</>,
  background: {
    color: "linear-gradient(180deg, #101218 0%, #212055 100%);",
    mobile: "/assets/images/home/carousel/carousel_item_mo_sql.png",
    desktop: "/assets/images/home/carousel/carousel_item_web_sql.png",
  },
  cta: {
    text: "더 알아보기",
    href: `online/sql`,
  },
  bannerName: "sqld 자격증 대비반",
  bannerTitle: "지금 SQL 수강신청 시 자격증 대비반 무료 참여 기회!",
};

export const defaultCarouselData = [sql, rcmdsFree, big_thing, hanghae];
