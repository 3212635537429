import styled from "@emotion/styled";
import {
  neutralDay,
  wBody3,
  wHeader2,
  wTitle4,
} from "@teamsparta/design-system";

export const Container = styled.div<{ isExpanded; bgColor }>`
  display: flex;

  width: ${({ isExpanded }) => (isExpanded ? "640px" : "146px")};
  height: 100%;
  overflow: hidden;
  transition: width 0.5s ease;

  background: ${({ bgColor }) => bgColor};
  border-radius: 12px;
  position: relative;
`;

export const ExpandedContainer = styled.div<{ isExpanded }>`
  display: flex;
  transition: opacity 0.5s ease;
  opacity: ${({ isExpanded }) => (isExpanded ? 1 : 0)};

  width: 640px;
  overflow: hidden;
  flex-shrink: 0;
  position: absolute;
  left: 0;
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 32px 0 32px 32px;
  width: calc(100% - 318px);
`;

export const TitleContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
`;

export const CourseTitleBadge = styled.div`
  display: flex;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 23px;
  border: 1px solid ${neutralDay.white};
  flex-shrink: 0;
`;

export const CourseTitle = styled.p`
  ${wBody3};
  color: ${neutralDay.white};
  text-align: center;
  flex-shrink: 0;
`;

export const Title = styled.h1`
  ${wHeader2};
  color: ${neutralDay.white};
  white-space: pre-wrap;
`;

export const Desc = styled.p`
  ${wBody3};
  color: ${neutralDay.white};
  opacity: 0.8;

  white-space: pre-wrap;
  word-break: keep-all;
`;

export const LinkBtnWrap = styled.div`
  filter: drop-shadow(0px 8px 24px rgba(95, 102, 107, 0.12));
  cursor: pointer;
  z-index: 1;
  width: fit-content;
`;

export const RightContainer = styled.div`
  width: 318px;
  height: 100%;
`;

export const UnExpandedContainer = styled.div<{ isExpanded }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transition: opacity 0.5s ease;
  opacity: ${({ isExpanded }) => (isExpanded ? 0 : 1)};

  ${({ isExpanded }) => !isExpanded && `cursor: pointer;`}

  position: absolute;
  left: 0;

  height: 360px;
  gap: 76px;
  width: 146px;
  flex-shrink: 0;
`;

export const UnExpandedTitle = styled.h2`
  ${wTitle4};
  color: ${neutralDay.white};
  padding: 24px 24px 0 24px;

  word-break: keep-all;
`;
