import styled from "@emotion/styled";
import {
  mCaption1,
  mCaption2,
  mHeader2,
  mTitle3,
  neutralDay,
  scc,
  wBody1,
  wHeader1,
} from "@teamsparta/design-system";
import { Bisection, DisplayResolution } from "src/styles/themes/device";

export const HeroSection = styled.section`
  position: relative;
  height: fit-content;
  width: 100%;
`;

export const BackGroundColor = styled.div`
  display: flex;
  width: 100%;
  padding-top: 88px;
  justify-content: center;
  align-items: center;
  background: linear-gradient(90deg, #fff1f4 0%, #fff6f8 100%);

  position: absolute;
  top: 0;

  ${DisplayResolution.TabletAndDesktop} {
    padding-top: 234px;
  }
`;

export const BackGroundInnerColor = styled.div`
  width: 100%;
  height: 48px;
  flex-shrink: 0;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
  z-index: 1;

  ${DisplayResolution.TabletAndDesktop} {
    height: 136.214px;
  }
`;

export const ContentsWrap = styled.div`
  padding: 32px 16px 0;
  position: relative;

  ${DisplayResolution.TabletAndDesktop} {
    padding: 108px 24px 0;
  }
`;

export const ContentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;

  ${DisplayResolution.TabletAndDesktop} {
    gap: 88px;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: flex-start;
  z-index: 2;
  width: 100%;
  ${Bisection.Narrow} {
    align-items: flex-start;
  }
`;

export const SubTitle = styled.p`
  ${mCaption1};
  color: ${neutralDay.gray80};
  text-align: center;

  ${DisplayResolution.TabletAndDesktop} {
    ${wBody1};
    color: ${neutralDay.gray80};
  }
`;

export const TitleInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${Bisection.Narrow} {
    align-items: flex-start;
  }
`;

export const RollingTextWrap = styled.div`
  height: 28px;

  ${DisplayResolution.TabletAndDesktop} {
    height: 59px;
  }
`;

export const Title = styled.h1`
  ${mHeader2};
  color: ${neutralDay.gray100};

  ${DisplayResolution.TabletAndDesktop} {
    ${wHeader1};
  }
`;

export const TitleRed = styled(Title)`
  display: flex;
  color: ${scc.red100} !important;

  align-items: center;
  gap: 4px;
  ${Bisection.Narrow} {
    align-items: flex-start;
  }
`;

export const IconWrap = styled.div`
  svg {
    width: 16px;
    height: 16px;
  }
  ${DisplayResolution.TabletAndDesktop} {
    svg {
      width: 24px;
      height: 24px;
    }
  }
`;

export const IconContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-template-rows: repeat(2, auto);
  gap: 16px;
  width: 100%;
  z-index: 2;

  ${DisplayResolution.Tablet} {
    grid-template-columns: repeat(auto-fill, minmax(100px, auto));
    grid-template-rows: repeat(2, auto);
    gap: 32px;
  }

  ${DisplayResolution.MiniDesktopAndDesktop} {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;
  }
`;

export const LabelIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  position: relative;

  ${DisplayResolution.TabletAndDesktop} {
    width: 100px;
    gap: 16px;
  }
`;

export const IconBadge = styled.div`
  display: flex;
  padding: 2px 6px;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -10px;

  border-radius: 6px;
  border: 1px solid ${scc.red100};

  color: ${scc.red100};
  text-align: center;

  font-family: Pretendard;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;

  background: #fff;

  ${DisplayResolution.TabletAndDesktop} {
    top: -9px;
    font-size: 11px;
    line-height: 16px;
  }
`;

export const Icon = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 12px;
  border: 1px solid rgba(20, 22, 23, 0.08);
  background: ${neutralDay.white};
  box-shadow: 0px 8px 24px 0px rgba(129, 137, 143, 0.12);

  ${DisplayResolution.TabletAndDesktop} {
    box-shadow: 0px 8px 24px 0px rgba(95, 102, 107, 0.12);
  }
`;

export const IconLabel = styled.p`
  ${mCaption2};
  color: ${neutralDay.gray100};
  text-align: center;
  white-space: pre-wrap;
  word-break: keep-all;

  ${DisplayResolution.TabletAndDesktop} {
    ${mTitle3}
  }
`;

export const MainImage = styled.img`
  display: none;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  height: 370px;
  margin: 0 auto;

  ${DisplayResolution.Tablet} {
    display: block;
  }

  ${DisplayResolution.MiniDesktopAndDesktop} {
    display: block;
  }
`;
