import styled from "@emotion/styled";
import {
  mBody2,
  mHeader2,
  mTitle1,
  neutralDay,
  wHeader2,
  wTitle5,
} from "@teamsparta/design-system";
import { DisplayResolution } from "src/styles/themes/device";

export const Container = styled.section`
  display: flex;
  width: 100vw;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  padding: 0 16px;

  ${DisplayResolution.TabletAndDesktop} {
    align-items: center;
    gap: 40px;
    padding: 0 24px;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  ${DisplayResolution.TabletAndDesktop} {
    align-items: center;
  }
`;

export const Title = styled.h2`
  ${mHeader2};
  color: ${neutralDay.gray95};

  ${DisplayResolution.TabletAndDesktop} {
    ${wHeader2};
    color: ${neutralDay.gray95};
  }
`;

export const SubTitle = styled.h2`
  ${mBody2};
  color: ${neutralDay.gray70};

  ${DisplayResolution.TabletAndDesktop} {
    ${wTitle5};
    color: ${neutralDay.gray70};
  }
`;

export const MobileCardWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 24px;

  ${DisplayResolution.TabletAndDesktop} {
    display: none;
  }
`;

export const DesktopCardWrap = styled.div<{ columnCount: number }>`
  display: none;
  ${DisplayResolution.TabletAndDesktop} {
    display: grid;
    grid-template-columns: repeat(${({ columnCount }) => columnCount}, 1fr);

    gap: 40px 24px;
    max-width: 1152px;
  }
`;

export const CardContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 12px;
  width: 100vw;

  overflow-x: scroll;

  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  margin: 0 -16px;
  padding: 0 16px;
`;

export const ScrollBar = styled.div`
  display: flex;
  width: 70px;
  height: 4px;
  padding-right: 0;
  align-items: center;
  border-radius: 4px;
  background: ${neutralDay.gray20};
`;

export const ScrollBarPointer = styled.div<{ position: number }>`
  width: 41px;
  height: 4px;
  flex-shrink: 0;
  border-radius: 4px;
  background: ${neutralDay.gray95};
  transform: translateX(${(props) => props.position}px);
`;
