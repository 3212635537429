import styled from "@emotion/styled";
import { neutralDay } from "@teamsparta/design-system";
import { useEffect, useState } from "react";

type Props = {
  containerRef: React.RefObject<HTMLDivElement>;
  scrollBarWidth: number;
  scrollBarPointerWidth: number;
};

export const Scrollbar = ({
  containerRef,
  scrollBarWidth,
  scrollBarPointerWidth,
}: Props) => {
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    const container = containerRef.current;
    if (container) {
      const { scrollWidth, offsetWidth, scrollLeft } = container;
      const totalWidth = scrollWidth - offsetWidth;
      setScrollPosition((scrollLeft / totalWidth) * 100);
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
      container.scrollLeft = 0;
      setScrollPosition(0);
    }

    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [containerRef]);

  const pointerPosition =
    scrollPosition * (scrollBarWidth - scrollBarPointerWidth) * 0.01;

  return (
    <ScrollBar scrollBarWidth={scrollBarWidth}>
      <ScrollBarPointer
        scrollBarPointerWidth={scrollBarPointerWidth}
        position={pointerPosition}
      />
    </ScrollBar>
  );
};

const ScrollBar = styled.div<{ scrollBarWidth: number }>`
  display: flex;
  width: ${({ scrollBarWidth }) => scrollBarWidth}px;
  height: 4px;
  padding-right: 0;
  align-items: center;
  border-radius: 4px;
  background: ${neutralDay.gray20};
`;

const ScrollBarPointer = styled.div<{
  scrollBarPointerWidth: number;
  position: number;
}>`
  width: ${({ scrollBarPointerWidth }) => scrollBarPointerWidth}px;
  height: 4px;
  flex-shrink: 0;
  border-radius: 4px;
  background: ${neutralDay.gray95};
  transform: translateX(${(props) => props.position}px);
`;
