import * as S from "./carousel.style";
import { BannerCarouselItem } from "./BannerCarouselItem";
import { useRouter } from "next/router";
import { sendLogV2 } from "../../../../../businesslogics/_common/loggingV2";
import { moveToHrefOpenWindow } from "../../../../../businesslogics/home/HrefHandle";
import { CarouselSlide } from "src/models/home.d";

type Props = {
  carousel: CarouselSlide;
  isMoving: boolean;
}

export const BannerCarouselItems = ({ carousel, isMoving }:Props) => {
  const router = useRouter();

  return (
    <S.Container
      bg={carousel.background.color}
      onClick={(e) => {
        if (!isMoving) {
          moveToHrefOpenWindow(carousel.cta.link);
          sendLogV2("carousel_click", router.pathname, {
            carousel_title: carousel.title,
          });
        }
      }}
    >
      <BannerCarouselItem carousel={carousel} />
    </S.Container>
  );
};
