export const MiniSpartaLogo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <g clipPath="url(#clip0_802_44610)">
        <path
          d="M11.7523 0.299927L1.78934 2.48414L0 8.34601L4.04774 13.0069L7.113 12.3342L8.0012 15.6999L12.0448 11.3686L14.0107 10.825L15.8 4.96083L11.7523 0.299927Z"
          fill="#E8344E"
        />
        <path
          d="M11.5324 5.09998V5.64916H12.0564V6.19835H12.5805V6.74754H13.1041V7.29673H12.5805V7.84591H12.0564V8.3951H11.5329V8.94429H10.4852V8.3951H11.0088V7.84591H11.5324V7.29673H12.0564V6.74754H11.5329V6.19789H11.0093V5.6487H10.4857V5.09998H11.5324Z"
          fill="white"
        />
        <path
          d="M9.81596 5.19702H8.90234V5.72718H8.38615V6.26615H7.89769V6.79584H7.3815V7.33528H6.89257V7.86497H6.37638V8.40441H5.88745V8.94338H6.80153V8.40441H7.29V7.87425H7.80619V7.33528H8.29465V6.80559H8.81131V6.26615H9.29977V5.73646H9.81596V5.19702Z"
          fill="white"
        />
        <path
          d="M4.17166 8.99207V8.44288H3.64761V7.89369H3.12356V7.3445H2.59998V6.79532H3.12356V6.24613H3.64761V5.69694H4.1712V5.14868H5.21883V5.69787H4.69524V6.24706H4.17166V6.79624H3.64761V7.34543H4.1712V7.89462H4.69478V8.44381H5.21837V8.99299H4.17166V8.99207Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_802_44610">
          <rect
            width="15.8"
            height="15.8"
            fill="white"
            transform="translate(0 0.0999756)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
