import { NbcTrackCardType } from "src/models/home/homeV4.models";

export const convertNbcTrackCardImg = (
  trackKeys: string[],
  nbcTrackCards: NbcTrackCardType[],
): NbcTrackCardType[] => {
  const convertedTrackCard = nbcTrackCards.map((nbcTrackCard) => {
    const key = nbcTrackCard.track_key;
    const imgSrc = `/assets/images/home/v4/campcard_${key}.webp`;
    const defaultImgSrc = `/assets/images/home/v4/campcard_default.webp`;
    return {
      ...nbcTrackCard,
      track_key: trackKeys.includes(key) ? imgSrc : defaultImgSrc,
    };
  });

  return convertedTrackCard;
};
