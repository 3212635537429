import styled from "@emotion/styled";
import { mBody1, neutralDay, wTitle4 } from "@teamsparta/design-system";
import { DisplayResolution } from "src/styles/themes/device";

export const Container = styled.div`
  display: flex;
  width: 100vw;
  padding: 40px 16px 60px 16px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  background: ${neutralDay.gray0};

  ${DisplayResolution.TabletAndDesktop} {
    padding: 60px 120px 100px 120px;
  }
`;

export const Text = styled.p`
  ${mBody1};
  color: ${neutralDay.gray40};
  text-align: center;

  ${DisplayResolution.TabletAndDesktop} {
    ${wTitle4};
    color: ${neutralDay.gray40};
    text-align: center;
  }
`;
