import { KdcAccordionCard } from "src/components/molecules/home/v4/KdcAccordionCard/KdcAccordionCard";
import * as S from "./kdcRecommend.style";
import { useEffect, useRef, useState } from "react";
import { KdcRecommendData } from "src/models/home/homeV4/KdcRecommend.models";
import { KdcCourseCard } from "src/components/molecules/home/v4/KdcCourseCard/KdcCourseCard";

export const KdcRecommend = () => {
  const [expanedIndex, setExpandedIndex] = useState<number | null>(0);
  const onClickAccordionItem = (index: number) => {
    setExpandedIndex(index);
  };

  const [activeIndex, setActiveIndex] = useState(0);
  const sliderRef = useRef(null);

  const handleScroll = () => {
    const index = Math.round(sliderRef.current.scrollLeft / 300);

    setActiveIndex(index);
  };

  useEffect(() => {
    const slider = sliderRef.current;
    slider.addEventListener("scroll", handleScroll);
    return () => slider.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <S.Container>
      <S.TitleContainer>
        <S.SubTitle>코딩 맛집</S.SubTitle>
        <S.Title>원하는 메뉴로 골라 들어요</S.Title>
      </S.TitleContainer>
      <S.DesktopWrap>
        <S.AccordionContainer>
          {KdcRecommendData.map((course, index) => (
            <KdcAccordionCard
              key={`accordionItem-${index}`}
              isExpanded={expanedIndex === index}
              handleExpand={() => {
                onClickAccordionItem(index);
              }}
              course={course}
            />
          ))}
        </S.AccordionContainer>
      </S.DesktopWrap>
      <S.MobileWrap ref={sliderRef}>
        {KdcRecommendData.map((course, index) => (
          <S.SnapItem key={`kdcCourseCard-${index}`}>
            <KdcCourseCard
              courseData={course}
              isActive={index === activeIndex}
            />
          </S.SnapItem>
        ))}
      </S.MobileWrap>
    </S.Container>
  );
};
