import { useEffect, useState } from "react";
import { useWindow } from "../_common/window";

// TODO: 스타일로 리팩토링
const getCardColumnCount = (width: number): number => {
  if (width <= 528) return 2;
  if (width <= 697) return 3;
  if (width <= 819) return 4;
  if (width <= 905) return 2;
  if (width <= 1199) return 3;
  return 4; // 1200px 이상
};

export const useCardColumnCount = (): number => {
  const windowObj = useWindow();
  const [columnCount, setColumnCount] = useState(
    getCardColumnCount(windowObj?.innerWidth),
  );

  useEffect(() => {
    const handleResize = (event) => {
      const width = event.target.innerWidth;
      setColumnCount(getCardColumnCount(width));
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setColumnCount(getCardColumnCount(windowObj?.innerWidth));
  }, [windowObj?.innerWidth]);

  return columnCount;
};
