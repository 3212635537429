import { useEffect, useState } from "react";

export const useWindow = () => {
  const [wind, setWind] = useState<Window>(null);
  useEffect(() => {
    setWind(window);
  }, []);
  return wind;
};

export const useUserAgent = () => {
  const [ua, setUa] = useState("");
  useEffect(() => {
    setUa(window.navigator.userAgent);
  }, []);
  return ua;
};

export const useLocation = () => {
  const [h, setHref] = useState<string>("");
  useEffect(() => {
    setHref(window.location.href);
  }, []);
  return h;
};

export const useReferrer = () => {
  const [ref, setRef] = useState("");
  useEffect(() => {
    const { referrer } = document;
    setRef(referrer);
  }, []);
  return ref;
};
