import styled from "@emotion/styled";
import {
  mBody1,
  mHeader2,
  neutralDay,
  wHeader2,
  wTitle2,
} from "@teamsparta/design-system";
import { DisplayResolution } from "src/styles/themes/device";

export const Wrapper = styled.div``;

export const Container = styled.section`
  display: flex;

  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 60px 16px;
  gap: 30px;

  ${DisplayResolution.MiniDesktopAndDesktop} {
    gap: 60px;
    padding: 100px 24px;
    width: 100vw;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: flex-start;

  ${DisplayResolution.MiniDesktopAndDesktop} {
    align-items: center;
    gap: 16px;
    align-self: center;
  }
`;

export const SubTitle = styled.h2`
  ${mBody1};
  color: ${neutralDay.gray60};

  ${DisplayResolution.MiniDesktopAndDesktop} {
    ${wTitle2};
    color: ${neutralDay.gray60};
    text-align: center;
  }
`;

export const Title = styled.h1`
  ${mHeader2};
  color: ${neutralDay.gray100};

  ${DisplayResolution.MiniDesktopAndDesktop} {
    ${wHeader2};
    color: ${neutralDay.gray95};
    text-align: center;
  }
`;

export const AccordionContainer = styled.div`
  display: flex;
  height: 360px;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  gap: 24px;

  width: 1152px;
`;

export const DesktopWrap = styled.div`
  display: none;

  ${DisplayResolution.MiniDesktopAndDesktop} {
    display: block;
  }
`;

export const MobileWrap = styled.div`
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  width: 100vw;
  margin: 0 -16px;
  padding: 0 30%;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (min-width: 500px) {
    scroll-snap-type: none;
    padding: 16px;
  }
  ${DisplayResolution.MiniDesktopAndDesktop} {
    display: none;
  }
`;

export const SnapItem = styled.div`
  flex: none;
  text-align: center;
  scroll-behavior: smooth;
  scroll-snap-align: center;
`;
