import styled from "@emotion/styled";
import {
  additional,
  mBody3,
  mHeader1,
  mTitle1,
  mTitle2,
  neutralDay,
  wHeader1,
  wTitle1,
  wTitle2,
} from "@teamsparta/design-system";
import { DisplayResolution } from "src/styles/themes/device";

export const Container = styled.section`
  display: flex;
  width: 100vw;
  padding: 60px 16px;
  flex-direction: column;
  gap: 30px;
  background: ${neutralDay.gray0};

  ${DisplayResolution.TabletAndDesktop} {
    align-items: center;
    gap: 60px;
    padding: 100px 16px;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${DisplayResolution.TabletAndDesktop} {
    width: 100%;
    max-width: 1152px;
    align-self: center;
  }
`;

export const Title = styled.h1`
  ${mHeader1};
  color: ${neutralDay.gray95};

  ${DisplayResolution.TabletAndDesktop} {
    ${wHeader1};
    color: ${neutralDay.gray95};
  }
`;

export const ColorTitle = styled(Title)`
  background: linear-gradient(90deg, #fc8a97 0%, #e8344e 42.94%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  align-self: stretch;

  ${DisplayResolution.TabletAndDesktop} {
    flex-direction: row;
    gap: 24px;
    max-width: 1152px;
    align-self: center;
  }
`;

export const Card = styled.button`
  display: flex;
  padding: 24px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 12px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid ${neutralDay.gray20};
  background: ${neutralDay.white};
  box-shadow: 0px 8px 24px 0px rgba(129, 137, 143, 0.12);

  ${DisplayResolution.TabletAndDesktop} {
    width: 368px;
    height: 438px;
    gap: 16px;
    padding: 32px;
    border-radius: 12px;

    &:hover {
      transform: translateY(-12px);
      transition: all 0.2s ease-in-out;
    }
  }
`;

export const CardTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;

  ${DisplayResolution.TabletAndDesktop} {
    gap: 12px;
  }
`;

export const CardTagBagde = styled.div<{ badgeColor }>`
  display: flex;
  padding: 2px 6px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: ${({ badgeColor }) => badgeColor};
`;

export const CardTag = styled.p`
  ${mBody3};
  color: ${neutralDay.white};
`;

export const CardTitle = styled.p`
  ${mTitle1};
  color: #000;

  ${DisplayResolution.TabletAndDesktop} {
    ${wTitle1};
  }
`;

export const CardDesc = styled.p`
  ${mTitle2};
  color: ${neutralDay.gray60};
  white-space: pre-wrap;
  text-align: left;

  ${DisplayResolution.TabletAndDesktop} {
    ${wTitle2};
    color: ${neutralDay.gray60};
  }
`;

export const CardImage = styled.img`
  aspect-ratio: 288/ 142.105;
  width: 100%;
  height: 100%;

  ${DisplayResolution.TabletAndDesktop} {
    aspect-ratio: 304 / 150;
    width: 100%;
    height: 100%;
  }
`;

export const Cta = styled.div`
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  gap: 2px;
  border-radius: 40px;
  background: ${neutralDay.gray20};
  flex-shrink: 0;

  svg {
    width: 16px;
    height: 16px;
  }

  ${DisplayResolution.TabletAndDesktop} {
    width: 52px;
    height: 52px;
    gap: 4px;
    svg {
      width: 20px;
      height: 20px;
    }
  }
`;
