import { CourseCard } from "src/components/molecules/home/v4/CourseCard/CourseCard";
import * as S from "./courseRecommend.style";
import { useCustomRemoteConfig } from "src/businesslogics/_common/hackle/useCustomRemoteConfig";
import { useRef } from "react";
import { Scrollbar } from "src/components/atoms/Scrollbar/Scrollbar";
import { useCardColumnCount } from "src/businesslogics/catalog/getCardColumnCount";
import { CourseRecommendType } from "src/models/home/homeV4.models";

export const CourseRecommend = () => {
  const courseData: CourseRecommendType = useCustomRemoteConfig(
    "scc_main_recommend_course_cards",
    "json"
  );

  const cardContainerRef = useRef<HTMLDivElement | null>(null);
  const cardColumnCount = useCardColumnCount();

  return (
    courseData && (
      <S.Container>
        <S.TextContainer>
          <S.Title>{courseData.title}</S.Title>
          <S.SubTitle>{courseData.description}</S.SubTitle>
        </S.TextContainer>

        <S.MobileCardWrap>
          <S.CardContainer ref={cardContainerRef}>
            {courseData?.courseCards.map((courseCard, index) => (
              <CourseCard
                key={`courseCard-${courseCard.title}-${index}`}
                courseCard={courseCard}
              />
            ))}
          </S.CardContainer>
          <Scrollbar
            containerRef={cardContainerRef}
            scrollBarWidth={70}
            scrollBarPointerWidth={41}
          />
        </S.MobileCardWrap>
        <S.DesktopCardWrap columnCount={cardColumnCount}>
          {courseData?.courseCards.map((courseCard, index) => (
            <CourseCard
              key={`courseCard-${courseCard.title}-${index}`}
              courseCard={courseCard}
            />
          ))}
        </S.DesktopCardWrap>
      </S.Container>
    )
  );
};
