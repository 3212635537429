import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Children, MutableRefObject, ReactElement, cloneElement } from "react";
import { useEffect, useRef, useState } from "react";
import { useInterval } from "src/businesslogics/community/review";
import { Bisection } from "src/styles/themes/device";

type Props = {
  children: ReactElement[];
};

/** children의 height 값이 다른 경우 제대로 동작하지 않음 */
export const RollingWrap = ({ children }: Props) => {
  const [itemIndex, setItemIndex] = useState(0);
  const [itemHeight, setItemHeight] = useState(0);
  const childRefs: MutableRefObject<any[]> = useRef(
    new Array(children.length).fill(null)
  );

  useEffect(() => {
    if (childRefs.current[itemIndex]) {
      setItemHeight(childRefs.current[itemIndex].offsetHeight);
    }
  }, [children]);

  useEffect(() => {
    childRefs.current = childRefs.current.slice(0, children.length);
  }, [children]);

  useInterval(() => {
    setItemIndex((prevItemIndex) => (prevItemIndex + 1) % children.length);
  }, 3000);

  return (
    <RollingTextContainer height={itemHeight}>
      <AnimationWrap
        height={itemHeight}
        css={css`
          transform: translateY(-${itemIndex * 100}%);
          transition: transform 0.3s ease-in-out;
        `}
      >
        {Children.map(children, (child, index) =>
          cloneElement(child, {
            ref: (el) => {
              childRefs.current[index] = el;
            },
          })
        )}
      </AnimationWrap>
    </RollingTextContainer>
  );
};

const RollingTextContainer = styled.div<{ height }>`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  height: ${({ height }) => height}px;
`;

const AnimationWrap = styled.div<{ height; css }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: ${({ height }) => height}px;
  ${({ css }) => css}
  ${Bisection.Narrow} {
    align-items: flex-start;
  }
`;
