import styled from "@emotion/styled";
import { mCaption1, mTitle1, neutralDay } from "@teamsparta/design-system";

export const Container = styled.div<{ bgColor; isCenter }>`
  display: inline-flex;
  padding-top: 32px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 8px;
  background: ${({ bgColor }) => bgColor};

  height: 344px;
  width: 300px;

  transition: transform 0.3s ease;
  transform: ${({ isCenter }) => (isCenter ? "scale(1)" : "scale(0.9)")};

  @media (min-width: 500px) {
    margin: 0 8px;
    transform: scale(1);
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  width: 300px;
  flex-direction: column;
  align-items: center;
  gap: 12px;
`;

export const TitleBadge = styled.div`
  display: flex;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 23px;
  border: 1px solid ${neutralDay.white};
`;

export const Title = styled.h1`
  ${mCaption1};
  color: ${neutralDay.white};
  text-align: center;
`;

export const SubTitle = styled.p`
  ${mTitle1};
  color: ${neutralDay.white};
  text-align: center;
  white-space: pre-line;
`;

export const Desc = styled.p`
  ${mCaption1};
  color: rgba(255, 255, 255, 0.7);
  text-align: center;

  white-space: pre-line;
`;
