import styled from "@emotion/styled";
import {
  mBody1,
  mCaption2,
  neutralDay,
  wBody4,
  wTitle1,
  wTitle5,
} from "@teamsparta/design-system";
import { DisplayResolution, HomeV3 } from "../../../../../styles/themes/device";

export const Container = styled.div<{ bg }>`
  display: flex;
  justify-content: center;
  cursor: pointer;

  width: 100%;
  height: 100%;
  background: ${({ bg }) => bg};
  flex-shrink: 0;
`;

export const ItemWrapper = styled.div<{ bg }>`
  z-index: 0;
  position: relative;
  display: flex;
  align-items: center;

  width: 100%;
  height: 100%;
  background: ${({ bg }) => bg.color};

  padding: 0 16px;

  ${DisplayResolution.TabletAndDesktop} {
    padding: 32px 0 32px 60px;
  }
`;

export const ItemTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  width: 337px;
  filter: drop-shadow(0px 8px 24px rgba(0, 0, 0, 0.2));
`;

export const ItemTitle = styled.div`
  color: ${neutralDay.white} !important;
  ${mBody1};
  ${DisplayResolution.TabletAndDesktop} {
    ${wTitle1};
  }
`;

export const ItemSubTitle = styled.div`
  color: ${neutralDay.white} !important;
  ${mCaption2};
  opacity: 0.8;

  ${DisplayResolution.TabletAndDesktop} {
    ${wTitle5};
  }
`;

export const ItemImage = styled.img`
  position: absolute;
  height: 100%;
  right: 0;
  z-index: -1;
`;
