export const LinkBtn = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 44C34.1503 44 44 34.1503 44 22C44 9.84974 34.1503 0 22 0C9.84974 0 0 9.84974 0 22C0 34.1503 9.84974 44 22 44ZM21.9597 26.7071C21.5692 26.3166 21.5692 25.6834 21.9597 25.2929L24.2526 23H17.3335C16.7812 23 16.3335 22.5523 16.3335 22C16.3335 21.4477 16.7812 21 17.3335 21H24.2526L21.9597 18.7071C21.5692 18.3166 21.5692 17.6834 21.9597 17.2929C22.3503 16.9024 22.9834 16.9024 23.3739 17.2929L27.3739 21.2929C27.7645 21.6834 27.7645 22.3166 27.3739 22.7071L23.3739 26.7071C22.9834 27.0976 22.3503 27.0976 21.9597 26.7071Z"
        fill="white"
      />
    </svg>
  );
};
