import styled from "@emotion/styled";
import {
  mBody1,
  mHeader2,
  mTitle1,
  neutralDay,
  wHeader2,
  wTitle1,
  wTitle2,
  wTitle4,
} from "@teamsparta/design-system";
import { DisplayResolution } from "src/styles/themes/device";

export const MobileContainer = styled.section`
  display: flex;
  width: 100vw;
  padding: 60px 16px;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  background: ${neutralDay.white};

  ${DisplayResolution.Desktop} {
    display: none;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;

  ${DisplayResolution.Desktop} {
    align-self: center;
    width: 100%;
    max-width: 1200px;
    gap: 16px;
  }
`;

export const SubTtile = styled.h2`
  ${mBody1};
  color: ${neutralDay.gray60};

  ${DisplayResolution.Desktop} {
    ${wTitle2};
    color: ${neutralDay.gray60};
  }
`;

export const Title = styled.h1`
  ${mHeader2};
  color: ${neutralDay.gray95};

  ${DisplayResolution.Desktop} {
    ${wHeader2};
    color: ${neutralDay.gray95};
  }
`;

export const Desc = styled.p`
  ${mBody1};
  align-self: stretch;
  color: ${neutralDay.gray60} !important;

  b {
    color: ${neutralDay.gray80} !important;
  }
  ${DisplayResolution.Desktop} {
    ${wTitle4};
  }
`;

export const Divide = styled.div`
  width: 100%;
  height: 1px;
  align-self: stretch;
  background: ${neutralDay.gray20};
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;

  gap: 24px;
`;

export const CardTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
  border-radius: 8px;
`;

export const CardSubTitle = styled.h3`
  ${mBody1};
  color: ${neutralDay.gray70};
`;

export const CardTitle = styled.h2`
  ${mTitle1};
  color: ${neutralDay.gray90};
`;

export const CardImage = styled.img<{ isShow }>`
  aspect-ratio: 328 / 215.25;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  transition: opacity 0.5s ease-in-out;

  ${DisplayResolution.Desktop} {
    position: absolute;
    opacity: ${({ isShow }) => (isShow ? 1 : 0)};
    right: 24px;
    aspect-ratio: 328 / 215.25;
    width: 640px;
    height: 420px;
    border-radius: 12px;
  }
`;

export const DesktopWrap = styled.section`
  display: none;

  ${DisplayResolution.Desktop} {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    height: 200vh;
    position: relative;
    padding: 100px 24px;
  }
`;

export const Container = styled.div`
  display: none;
  ${DisplayResolution.Desktop} {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    justify-content: flex-start;

    padding: 0px 24px;
    gap: 60px;

    width: 100%;
    max-width: 1200px;

    position: sticky;
    top: 20%;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
`;

export const ContentTextContainer = styled.div`
  display: flex;
  width: 400px;
  flex-direction: column;
  align-items: flex-start;
  gap: 41px;
`;

export const ActiveBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`;

export const ActiveBox = styled.button<{ isActive }>`
  display: flex;
  padding: 24px 20px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 12px;
  background: ${neutralDay.gray0};
  background: ${({ isActive }) =>
    isActive ? neutralDay.gray0 : neutralDay.white};
`;

export const BoxTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
`;

export const BoxSubTitle = styled.h4<{ isActive }>`
  ${wTitle4};
  color: ${({ isActive }) =>
    isActive ? neutralDay.gray70 : neutralDay.gray40};
  text-align: center;
`;

export const BoxTitle = styled.h3<{ isActive }>`
  ${wTitle1};
  color: ${({ isActive }) =>
    isActive ? neutralDay.gray90 : neutralDay.gray50};
  text-align: center;
`;
