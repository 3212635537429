export type KdcRecommendType = {
  slug: string;
  courseTitle: string;
  subTitle: string;
  description: string;
  nextUrl: string;
  bgColor: string;
};

const web_basic: KdcRecommendType = {
  slug: "web_basic",
  courseTitle: "[왕초보] 웹개발 종합반",
  subTitle: "나만의 웹사이트를\n갖고 싶어",
  description:
    "영화 기록 사이트, 추억 사진첩 사이트를 만들며\n웹사이트의 기본 구조와 작동 원리를 익혀보세요.",
  nextUrl: `${process.env.BASE_URL}/online/web_basic`,
  bgColor: "linear-gradient(180deg, #FFAE50 0%, #FADBBF 100%);",
};

const gpt_300_online: KdcRecommendType = {
  slug: "gpt_300_online",
  courseTitle: "ChatGPT 300% 활용하기",
  subTitle: "AI? ChatGPT?\n그게 뭔데?",
  description:
    "똑똑하다고 소문 난 인공지능,  제대로 질문하는 법을\n배워 나만의 업무 자동화 프로그램으로 활용하세요!",
  nextUrl: `${process.env.BASE_URL}/online/gpt_300_online`,
  bgColor: "#AD90FF",
};

const data_v2: KdcRecommendType = {
  slug: "data_v2",
  courseTitle: "데이터 분석 종합반",
  subTitle: "데이터 몰라서\n멍 때렸던 시간들 이제 안녕",
  description:
    "탄탄한 의사결정을 위한 가장 강력한 도구, 데이터!\n가설 설계부터 실제 분석까지 탄탄하게 배워요.",
  nextUrl: `${process.env.BASE_URL}/online/data_v2`,
  bgColor: "#794DF4",
};

const app: KdcRecommendType = {
  slug: "app",
  courseTitle: "앱개발 종합반",
  subTitle: "개발자 없이\n아이디어를 실현하고 싶어",
  description:
    "나만의 빛나는 아이디어를 직접 앱으로 구현해서\n이 세상 하나뿐인 작품을 만들어 봐요!",
  nextUrl: `${process.env.BASE_URL}/online/app`,
  bgColor: "#66D7C5",
};

export const KdcRecommendData: KdcRecommendType[] = [
  web_basic,
  gpt_300_online,
  data_v2,
  app,
];
