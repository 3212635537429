import instance from "axios";
import { useQuery } from "react-query";

const getAccumulateUset = async () => {
  try {
    const response = await instance.get(
      `${process.env.API_URL}/social-proof/accumulated-registered-users`,
    );
    return response.data;
  } catch (e) {
    console.error(e);
  }
};

export const useCountAccumulateUser = () => {
  return useQuery<number>(["accumulateUser"], getAccumulateUset);
};
