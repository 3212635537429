import { useEffect, useState } from "react";

export const makeInfiniteCarousel = (CarouselItems) => {
  return [
    CarouselItems[CarouselItems.length - 1],
    ...CarouselItems,
    CarouselItems[0],
  ];
};

export const useInfiniteCarousel = (slides) => {
  const [carousel, setCarousel] = useState([]);
  useEffect(() => {
    if (!!slides) {
      setCarousel([slides[slides.length - 1], ...slides, slides[0]]);
    }
  }, [slides]);
  return carousel;
};
