import styled from "@emotion/styled";
import {
  mBody1,
  mBody2,
  mBody3,
  mHeader1,
  mHeader2,
  mTitle1,
  neutralDay,
  scc,
  wBody2,
  wHeader2,
  wTitle1,
  wTitle2,
  wTitle4,
} from "@teamsparta/design-system";
import { DisplayResolution } from "src/styles/themes/device";

export const BootCampContainer = styled.div``;

export const Container = styled.div`
  display: flex;
  width: 100vw;
  padding: 60px 16px;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  background: ${neutralDay.gray0};

  ${DisplayResolution.Desktop} {
    gap: 60px;
    padding: 100px 24px;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;

  ${DisplayResolution.Desktop} {
    width: 100%;
    gap: 16px;
    max-width: 1200px;
    align-self: center;
    padding: 0 24px;
  }
`;

export const SubTitle = styled.h2`
  ${mBody1};
  color: ${neutralDay.gray60};

  ${DisplayResolution.Desktop} {
    ${wTitle2};
    color: ${neutralDay.gray60};
  }
`;

export const Title = styled.h1`
  ${mHeader2};
  color: ${neutralDay.gray95};

  ${DisplayResolution.Desktop} {
    ${wHeader2};
    color: ${neutralDay.gray95};
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  width: 100%;

  ${DisplayResolution.Desktop} {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    gap: 40px;
    max-width: 1200px;
    padding: 0 24px;
  }
`;

export const ResultBox = styled.div`
  display: flex;
  padding: 20px;

  justify-content: center;
  align-items: center;
  gap: 32px;
  align-self: stretch;
  border-radius: 8px;
  background: ${neutralDay.gray10};

  ${DisplayResolution.Desktop} {
    display: flex;
    flex-direction: column;
    gap: 40px;
    border-radius: 12px;
    padding: 20px 40px;
    width: 178px;
    flex-shrink: 0;
  }
`;

export const ResultItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ResultTitle = styled.h3`
  ${mBody3};
  color: ${neutralDay.gray80};
  word-break: keep-all;
  text-align: center;

  ${DisplayResolution.Desktop} {
    ${wTitle4};
    color: ${neutralDay.gray80};
  }
`;

export const ResultValue = styled.h2`
  ${mHeader1};
  color: ${scc.red100};

  ${DisplayResolution.Desktop} {
    ${wHeader2};
    color: ${scc.red100};
  }
`;

export const ResultPersent = styled.span`
  ${mTitle1};
  color: ${scc.red100};

  ${DisplayResolution.Desktop} {
    ${wTitle1};
    color: ${scc.red100};
  }
`;

export const BootCampCourseContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;

  ${DisplayResolution.Desktop} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
`;

export const CourseBox = styled.div`
  display: flex;
  width: 100%;
  padding: 24px;
  align-items: flex-start;
  gap: 20px;
  border-radius: 12px;
  border: 1px solid ${neutralDay.gray20};
  background: ${neutralDay.white};
  box-shadow: 0px 8px 24px 0px rgba(129, 137, 143, 0.12);
  cursor: pointer;

  &:hover {
    transform: translateY(-8px);
    transition: transform 0.3s ease-in-out;
  }

  ${DisplayResolution.Desktop} {
    display: flex;
    padding: 24px;
    gap: 24px;
    flex: 1 0 0;
    width: 455px;
    height: 183px;
  }
`;

export const CourseImageWrap = styled.div`
  width: 60px;
  height: 60px;

  border-radius: 8px;
  position: relative;
  flex-shrink: 0;
  overflow: hidden;
  ${DisplayResolution.Desktop} {
    width: 135px;
    height: 135px;
  }
`;

export const CourseTextBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;

  ${DisplayResolution.Desktop} {
    gap: 14px;
  }
`;

export const CourseTextInnerBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;

  ${DisplayResolution.Desktop} {
    gap: 6px;
  }
`;

export const CourseTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;

export const CourseTitle = styled.h3`
  ${mBody1};
  color: ${neutralDay.gray95};

  ${DisplayResolution.Desktop} {
    ${wTitle4};
    color: ${neutralDay.gray95};
  }
`;

export const CourseTitleRed = styled(CourseTitle)`
  color: ${scc.red100} !important;
`;

export const FirstComeBox = styled.div`
  display: flex;
  padding: 2px 6px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 4px;
  background: ${neutralDay.gray20};
`;

export const FirstComeText = styled.p`
  color: ${neutralDay.gray70};
  font-family: Pretendard;
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 16.5px */

  ${DisplayResolution.Desktop} {
    ${mBody3};
    color: ${neutralDay.gray70};
  }
`;

export const CourseDesc = styled.p`
  ${mBody2};
  align-self: stretch;
  color: ${neutralDay.gray80};

  word-break: keep-all;

  ${DisplayResolution.Desktop} {
    ${wBody2};
    color: ${neutralDay.gray80};
  }
`;
